<template>
  <el-skeleton :loading="isLoading" animated>
    <template #template>
      <el-skeleton-item
        variant="circle"
        :style="getAvatarStyles"
      ></el-skeleton-item>
    </template>
  </el-skeleton>

  <img
    v-show="!isLoading"
    class="dog-avatar"
    :src="avatar"
    @load="loaded"
    :alt="dog.formalName"
    :style="getAvatarStyles"
  />
</template>

<script>
// This component takes the following props:
// dog - Object of dog information
// size - size of the avatar
import defaultAvatar from "../assets/defaultAvatar.png";
const envURL = process.env.VUE_APP_ENVURL;

export default {
  emits: ["imageLoaded"],
  props: {
    dog: Object,
    avatarSize: String,
  },
  data() {
    return {
      isLoading: true,
      avatar: undefined,
    };
  },
  computed: {
    getAvatarStyles() {
      return this.avatarSize
        ? `width: ${this.avatarSize}px; height: ${this.avatarSize}px;`
        : "width: 300px; height: 300px";
    },
  },
  methods: {
    hasAvatar() {
      this.avatar = this.dog.dogImage
        ? `${envURL}/uploads/${this.dog.dogImage}`
        : defaultAvatar;
    },
    loaded() {
      this.isLoading = false;
      this.$emit("imageLoaded", false);
    },
  },
  created() {
    this.hasAvatar();
  },
};
</script>

<style lang="scss" scoped>
.dog-avatar {
  border-radius: 50%;
  border: 1px solid $borderLight;
}

.registered .dog-avatar {
  border: 1px solid $secondary;
}
</style>
