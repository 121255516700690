<template>
  <div class="class-detail-wrapper">
    <div class="class-detail-left-section">
      <p class="primary trialClass">
        <strong>{{ trialClass.trialClass }}</strong>
      </p>
      <p>{{ trialClass.level }}</p>
      <p class="handler">
        <font-awesome-icon :icon="['fa', 'address-card']"></font-awesome-icon
        >{{ trialClass.judge }}
      </p>
    </div>
    <div
      class="class-detail-right-section"
      v-if="registeredDogs.length > 0 && trialOrDogView === 'trial'"
    >
      <p class="registered-header">Registered</p>
      <div class="registered-dogs">
        <div
          v-for="dog in registeredDogs"
          :key="dog._id"
          :class="isRegistered(dog)"
          @Click="handleRegisterDog(dog)"
        >
          <el-tooltip effect="dark" :content="dog.callName" placement="top">
            <div>
              <DogAvatar avatarSize="30" :dog="dog" />
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div
      class="class-detail-right-section"
      v-else-if="trialOrDogView === 'dog'"
    >
      <base-button @Click="registerDogFromDogView(getDog)" color="primary"
        >Register</base-button
      >
    </div>
    <div class="class-detail-right-section register-for-trial-button" v-else>
      <base-button @Click="registerDogsForTrial" color="primary"
        >Register For Trial</base-button
      >
    </div>
    <div class="remove-class-icon">
      <font-awesome-icon
        @Click="removeClass"
        :icon="['fa', 'minus-circle']"
      ></font-awesome-icon>
    </div>
  </div>
  <el-dialog
    title="Register dog(s) for trial"
    :modelValue="trialRegistrationsVisible"
    width="50%"
    :before-close="closeTrialRegistrations"
  >
    <TrialRegistrationDogList :trial="trial" />
    <div class="dialogue-buttons">
      <base-button color="primary" @click="closeTrialRegistrations"
        >Done</base-button
      >
    </div>
  </el-dialog>
</template>

<script>
import DogAvatar from "@/ui/DogAvatar.vue";
import TrialRegistrationDogList from "./TrialRegistrationDogList.vue";

export default {
  props: {
    trialClass: Object,
    registeredDogs: Array,
    trialOrDogView: String,
    upcomingOrPast: String,
    trial: Object,
  },
  components: { DogAvatar, TrialRegistrationDogList },
  data() {
    return {
      trialRegistrationsVisible: false,
    };
  },
  computed: {
    getDog() {
      return this.$store.getters["dog/getDog"];
    },
    ownerDogs() {
      return this.$store.getters["user/getUserProfile"].dogs;
    },
  },
  methods: {
    isRegistered(dog) {
      return this.trialClass.registrations.find(
        (registration) =>
          registration.dogId === dog._id && !registration.isDeleted
      )
        ? "registered"
        : "not-registered";
    },
    async handleRegisterDog(dog) {
      const isRegistered = this.isRegistered(dog);

      isRegistered === "registered"
        ? this.removeRegistration(dog)
        : this.registerDog(dog);
    },
    async registerDog(dog) {
      try {
        await this.$store.dispatch("trial/registerForTrialClass", {
          trialId: this.trialClass.trialId,
          classId: this.trialClass._id,
          dogId: dog._id,
          handler: dog.handlers[0] ? dog.handlers[0].handlerName : "no handler",
        });

        this.$message({
          showClose: true,
          message: `${dog.callName} registered for ${this.trialClass.trialClass}`,
          type: "success",
          duration: 5000,
        });
      } catch (error) {
        console.log(error);
        this.$message({
          showClose: true,
          message: "Error TCD1000 registering dog for trial class",
          type: "error",
          duration: 5000,
        });
      }
    },
    async registerDogsForTrial() {
      this.trialRegistrationsVisible = true;
    },
    async closeTrialRegistrations() {
      this.trialRegistrationsVisible = false;
    },
    async registerDogFromDogView(dog) {
      try {
        await this.$store.dispatch("dog/registerForTrialClassDogView", {
          trialId: this.trialClass.trialId,
          classId: this.trialClass._id,
          dogId: dog._id,
          handler: dog.handlers[0] ? dog.handlers[0].handlerName : "no handler",
        });

        this.$message({
          showClose: true,
          message: `${dog.callName} registered for ${this.trialClass.trialClass}`,
          type: "success",
          duration: 5000,
        });
      } catch (error) {
        console.log(error);
        this.$message({
          showClose: true,
          message: "Error TCD1000 registering dog for trial class",
          type: "error",
          duration: 5000,
        });
      }
    },
    async removeRegistration(dog) {
      this.$confirm(
        `Are you sure you want to remove ${dog.callName} from ${this.trialClass.trialClass} and delete all stats for this class?`,
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            await this.$store.dispatch("trial/removeFromTrialClass", {
              trialId: this.trialClass.trialId,
              classId: this.trialClass._id,
              dogId: dog._id,
            });

            this.$message({
              showClose: true,
              message: `${dog.callName} removed from ${this.trialClass.trialClass}`,
              type: "error",
              duration: 5000,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error TCD1100 removing dog from trial class",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch(() =>
          this.$message({
            type: "info",
            message: "Remove Cancelled",
          })
        );
    },
    async removeClass() {
      if (this.trialClass.registrations.length === 0) {
        this.$confirm(
          `Are you sure you want to delete ${this.trialClass.trialClass} ${this.trialClass.level} from the trial?`,
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.$store.dispatch("trial/removeTrialClassFromTrial", {
              trialClass: this.trialClass,
              upcomingOrPast: this.upcomingOrPast,
              update: { isDeleted: true },
            });
            this.$message({
              type: "success",
              message: "Delete Successful",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Delete Cancelled",
            });
          });
      } else {
        this.$confirm(
          `Currently there are ${this.trialClass.registrations.length} dogs registered. Please remove them in order to delete this class.`,
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.$message({
              type: "info",
              message: "Delete Cancelled",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Delete Cancelled",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Class detail styles are handled in the _variable.scss file

.remove-class-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;

  svg {
    opacity: 0.5;
  }

  svg:hover {
    color: $secondary;
    opacity: 1;
    cursor: pointer;
  }
}

.register-for-trial-button {
  margin-top: 20px;
}

.dialogue-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
