<template>
  <base-spinner v-show="isLoading"></base-spinner>
  <div class="registered-dogs" v-show="!isLoading">
    <div
      v-for="dog in ownerDogs"
      :key="dog._id"
      :class="isRegistered(dog)"
      @Click="handleRegisterDog(dog)"
    >
      <el-tooltip effect="dark" :content="dog.callName" placement="top">
        <div>
          <DogAvatar :dog="dog" avatarSize="35" @imageLoaded="imageLoaded" />
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import DogAvatar from "@/ui/DogAvatar";

export default {
  props: { trial: Object },
  components: { DogAvatar },
  data() {
    return {
      isLoading: true,
      avatarCount: 0,
      activeTrial: this.trial,
    };
  },
  computed: {
    ownerDogs() {
      return this.$store.getters["user/getUserProfile"].dogs;
    },
  },
  methods: {
    getUpcomingOrPast() {
      const trialDate = new Date(this.trial.startDate);
      const currentDate = new Date();

      if (trialDate >= currentDate) {
        return "upcoming";
      }

      if (trialDate < currentDate) {
        return "past";
      }
    },
    imageLoaded() {
      this.avatarCount++;
      if (this.avatarCount === this.ownerDogs.length) {
        this.isLoading = false;
      }
    },
    isRegistered(dog) {
      return this.activeTrial.trialRegistrations.find(
        (dogId) => dogId === dog._id
      )
        ? "registered"
        : "not-registered";
    },
    async handleRegisterDog(dog) {
      const isRegistered = this.isRegistered(dog);

      isRegistered === "registered"
        ? this.removeRegistration(dog)
        : this.registerDog(dog);
    },
    async registerDog(dog) {
      try {
        this.$store.dispatch("trial/registerForTrial", {
          trialId: this.trial._id,
          dogId: dog._id,
        });

        this.activeTrial.trialRegistrations.push(dog._id);

        this.$message({
          showClose: true,
          message: `${dog.callName} registered for ${this.trial.trialName}`,
          type: "success",
          duration: 5000,
        });
      } catch (error) {
        console.log(error);
        this.$message({
          showClose: true,
          message: "Error OTL1000 registering dog for trial",
          type: "error",
          duration: 5000,
        });
      }
    },
    async removeRegistration(dog) {
      this.$confirm(
        `Are you sure you want to remove ${dog.callName} from ${this.trial.trialName} which includs removal of all classes and results?`,
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          try {
            this.$store.dispatch("trial/removeDogFromTrial", {
              dogId: dog._id,
              trialId: this.trial._id,
              upcomingOrPast: this.getUpcomingOrPast(),
            });

            const updatedTrialRegistrations =
              this.activeTrial.trialRegistrations.filter(
                (registration) => registration !== dog._id
              );

            this.activeTrial.trialRegistrations = updatedTrialRegistrations;

            this.$message({
              showClose: true,
              message: `${dog.callName} removed from ${this.trial.trialName}`,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error OTL1100 removing dog from trial",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch(() =>
          this.$message({
            type: "info",
            message: "Remove Cancelled",
          })
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.registered-dogs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .registered {
    opacity: 1;
  }

  .not-registered {
    opacity: 0.4;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    margin: 0 5px;
  }

  div:hover {
    cursor: pointer;
    opacity: 1;
  }
}
</style>
